.svgStyle {
  max-width: 100%;
  padding-bottom: 5vh;
}
.svgStyle:hover {
  cursor: pointer;
}

.selected > path {
  fill: #1f1bff;
  transition: fill 0.5s;
}

.unselected > path {
  fill: black;
  transition: fill 0.5s;
}
