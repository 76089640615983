.NavBar {
  background-color: blue;
  /* position: absolute;
  top: 90vh; */
  width: 100vw;
  /* height: 10vh; */
  height: calc(var(--vh, 1vh) * 10);
  /* flex: 10; */
  display: flex;
}
.buttonBar {
  /* background-color: yellow; */
  width: 80%;
  height: 72%;
  margin: auto;
  display: flex;
  margin: auto;
  justify-content: space-between;
}
.buttonBar img {
  max-height: 100%;
}
