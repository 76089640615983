.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
}
.Container {
  background: white;
  border-radius: 5px;
  padding: 20px;
  animation: fade 0.5s ease;
}
.AboutMe {
  border-bottom: 0.1px solid black;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.separator {
}
.resume {
  font-size: 20px;
  /* border: 1px solid black; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(229, 229, 229, 1); */
  background: #e5e5e5;
  width: 50%;
  margin: auto;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
