.GalleryContainer {
  /* height: 963px; */
  /* height: 100vh; */
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Header {
  flex: 10;
  text-align: center;
}
.BodyContent {
  flex: 90;
  display: flex;
  flex-direction: column;
  padding-bottom: 4vh;
}
.PhotoSection {
  flex: 90;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Photos {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  width: 90%;
}
.Photos div {
  /* flex: 25; */
  /* width: 20%; */
  flex-basis: 25%;
}
.Photo {
  /* max-width: 100%;
  max-height: 100%; */
  /* margin: 1vmin; */
  width: 100%;
  height: 100%;
  z-index: 3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  filter: grayscale(1);
}
.Photo:hover {
  filter: grayscale(0);
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(0.97);
  transition: filter 0.5s, box-shadow 0.2s, transform 1.8s;
}
.PhotoContainer {
  position: relative;
}
.PhotoContainer:hover > .ImageDescription {
  opacity: 1;
  transition: opacity 0.5s;
}
.ImageDescription {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0;
  background: #ffffffe6;
  font-size: 1.4rem;
}
.PhotoNavigation {
  padding-top: 50px;
  padding-bottom: 50px;
  flex: 10;
  display: flex;
  justify-content: center;
  /* background-color: red; */
}
.NavBar {
  position: absolute;
  /* flex: 1; */
  /* background-color: blue; */
  width: 80%;
  display: flex;
}
.NavItem {
  flex: 1;
  /* background-color: blue; */
  border-top: 1px solid black;
}
.NavItem:hover {
  border-top: 5px solid blue;
  cursor: pointer;
  /* background-color: red; */
}

@media (max-width: 800px) {
  .PhotoContainer img {
    height: 40vh;
  }
  .ImageDescription {
    position: initial;
    background: none;
  }
  .Photos {
    width: initial;
    flex-wrap: initial;
    padding-bottom: 2vh;
  }
  .Photo {
    width: initial;
    margin: 1vw;
  }
}
