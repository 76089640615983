:root {
  --color-sample: blue;
}

.HomeContainer {
  height: 100vh;
  border-bottom: 1px solid #b3b3b3;
}

.NavBarGap {
  height: 6%;
}

.background {
  position: relative;
  height: 94%;
}

.ellipse {
  position: absolute;
  padding-top: 10%;
  left: 5%;
  display: none;
}

.description {
  position: absolute;
  /* bottom: 10%; */
  bottom: 7%;
  left: 5%;
  z-index: 2;
  max-width: 90%;
  margin: auto;
}
.descriptionMain {
  /* font-size: 3rem; */
  /* font-size: 5.4vh; */
  font-size: calc(var(--vh, 1vh) * 5.4);
  background: white;
}
.descriptionSub {
  font-size: 1.45rem;
  max-width: 30vw;
}

.portraitWBack {
  height: 90%;
  max-width: 100%;
  position: absolute;
  right: 0;
}

.portraitSolo {
  text-align: center;
  height: 58vh;
  display: flex;
  align-items: center;
}
.portraitSolo > img {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 6px 5px 0px rgba(229, 229, 229, 1);
}
.descriptionInnerContainer {
  margin: auto;
}
.resumeText {
  font-weight: bold;
  /* font-size: 1.2rem; */
  font-size: calc(var(--vh, 1vh) * 2.3);
  /* font-size: 2.3vh; */
  /* margin: auto;
  padding-top: 10px; */
  text-decoration: underline;
  margin-bottom: 0;
  margin-top: 1vh;
}

@media (max-width: 1300px) {
  .portraitWBack {
    height: 50%;
    bottom: 20vh;
  }
}

@media (max-width: 1100px) {
  .ellipse {
    display: none;
  }
  .portraitWBack {
    max-width: 50%;
  }
  .descriptionSub {
    max-width: initial;
  }
  .description {
    max-width: 35%;
  }
}

@media (max-height: 650px) {
  .HomeContainer {
    height: 550px;
    position: relative;
  }
  .descriptionMain {
    font-size: 1.5rem;
  }
}

@media (max-width: 800px) {
  .HomeContainer {
    height: auto;
  }
  .portraitSolo {
    /* background: black; */
    height: calc(var(--vh, 1vh) * 58);
  }
  .description {
    /* bottom: 10%; */
    max-width: 90%;
    display: flex;
    position: static;
    height: calc(var(--vh, 1vh) * 32);
  }
  .background {
    /* height: 58vh; */
    height: calc(var(--vh, 1vh) * 58);
  }
  .descriptionMain {
    margin: auto;
    /* background: var(--color-sample); */
  }
  /* .background {
    position: initial;
    height: initial;
    height: 58vh;
  } */
  /* .descriptionMain {
    max-width: initial;
  } */
}

@media (min-height: 750px) and (min-width: 1000px) {
  .ellipse {
    display: block;
  }
}
