.Container {
  /* background-color: red; */
  width: 29%;
  height: 44%;
  overflow: auto;
  margin: 2vmin;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */

  background-color: white;
  /* animation: slide-up 0.4s ease; */
}
.appear {
  opacity: 1;
  transition: opacity 1s;
}
.disappear {
  opacity: 0.2;
  transition: opacity 1s;
}
/* @keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

.Container:hover {
  cursor: pointer;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.PhotoContainer img {
  border-radius: 5px 5px 0px 0px;
}
.Photo {
  /* REPLACED THESE TWO TO PREVENT IMAGE FROM GETTING TOO BIG VERTICALLY ON BIG SCREENS, CHANGE OBJECT-FIT AS NEEDED
  max-width: 100%;
  max-height: 100%; */
  width: 100%;
  max-height: 22vh;
  object-fit: cover;
}
.ProjectType {
  color: #676767;
  padding: 5px;
  align-items: center;
}
.Description {
  padding: 5px;
}

@media (max-width: 800px) {
  .Container {
    height: initial;
    width: 75%;
  }
  .Projects {
    display: flex;
  }
  .Description {
    padding-bottom: 2vh;
  }
}
