.svgStyle {
  /* position: fixed;
  top: 100px;
  left: 300px;
  fill: orange; */
  max-width: 100%;
  padding-bottom: 5vh;
}
.svgStyle:hover {
  cursor: pointer;
  /* transition: fill 2s;
  fill: blue; */
}

.selected > path {
  fill: #1f1bff;
  transition: fill 0.5s;
}

.unselected > path {
  fill: black;
  transition: fill 0.5s;
}

/*filter === "Startup" ? "selected" : "unselected"*/
