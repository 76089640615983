.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 15;
  background-color: white;
  /* opacity: 0.5; */
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Container {
  text-align: center;
  animation: slide-up 0.5s ease;
  padding-bottom: 15vh;
}

.CloseButton {
  position: fixed;
  /* top: 10%;
  right: 10%; */
  top: 80px;
  right: 80px;
  /* width: 5vmin; */
}
.CloseButton:hover {
  cursor: pointer;
}
.CloseButton img {
  width: 100%;
}

.Header {
  padding-top: 10vh;
}
.Header p {
  font-size: 18px;
  color: #1f1bff;
}

.Title {
  margin: auto;
  max-width: 52%;
  /* width: 52%; */
  font-size: 36px;
  font-family: "Arsenal", sans-serif;
  font-weight: bold;
}

.CoverImage {
  padding-top: 3vh;
}
.CoverImage > img {
  max-width: 66%;
  max-height: 100%;
}
.Credits {
  max-width: 50%;
  margin: auto;
}
.Credits p {
  margin: auto;
  text-align: left;
}
.Content {
  text-align: left;
  margin: auto;
}
.Content > * {
  width: 50%;
  margin: auto;
}
.Content div {
  text-align: center;
}
.Content p {
  font-size: 18px;
  margin-bottom: 2vh;
}
.Content > h3 {
  padding-top: 3vh;
}
.Content img {
  max-width: 100%;
  padding-top: 3vh;
}

@media (max-width: 800px) {
  .Title {
    font-size: 1.45rem;
    width: 86%;
    max-width: initial;
  }
  .Header {
    padding-top: 7vh;
  }
  .CoverImage {
    /* padding-top: initial; */
  }
  .CoverImage > img {
    max-width: 100%;
    max-height: initial;
  }
  .Credits {
    max-width: 92%;
  }
  .Content > * {
    width: 92%;
  }
  .Content > ul {
    width: 85%;
  }
  .CloseButton {
    /* width: 12vw; */
    width: 48px;
    right: 20px;
    top: 20px;
  }
}
