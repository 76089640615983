.title {
  font-size: 36px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.title::before {
  content: "—— ";
}
.title::after {
  content: " ——";
}

.subtitle {
  font-size: 29px;
  margin-top: 0;
  margin-left: 2vw;
  margin-right: 2vw;
}

@media (max-width: 800px) {
  .title {
    text-transform: initial;
  }
}
