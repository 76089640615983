.NavItem {
  flex: 1;
  /* background-color: blue; */
  border-top: 1px solid black;
  margin: 0 0.5vw;
}
.NavItem:hover {
  border-top: 5px solid blue;
  cursor: pointer;
  /* background-color: red; */
}
.selected {
  border-top: 5px solid blue;
}
.unselected {
  /* border-top: 5px solid red; */
}
