.WritingContainer {
  background-color: #4409ec;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  padding-bottom: 3vh;
}
.Header {
  flex: 10;
}
.bodyContent {
  flex: 90;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article {
  display: inline-block;
  padding: 5px 10px;
  background: #ffffff14;
  margin-bottom: 20px;
  border-radius: 5px;
}
.article p {
  margin: auto;
  /* display: inline;
  padding: 5px 10px;
  background: #ffffff14; */
}
.article:hover {
  cursor: pointer;
}
