.PortfolioContainer {
  /* background-color: pink; */
  background-color: #e5e5e5;
  /* height: 963px; */
  height: 100vh;
  display: flex;
}

.FiltersContainer {
  /* background-color: lightgreen; */
  background-color: #f5f5f5;
  flex: 8;
  text-align: center;
}
.ProjectsContainer {
  /* background-color: orange; */
  flex: 92;
  /* display: flex; */
  /* flex-direction: column; */
  padding-bottom: 2vh;
}

.Filters {
  /* background-color: yellow; */
  width: 71%;
  margin: auto;
  padding-top: 10vh;
}

.filter {
  max-width: 100%;
  padding-bottom: 5vh;
}

.filter:hover {
  cursor: pointer;
}

.Header {
  /* background-color: blue; */
  /* flex: 10; */
  /* height: 15%; */
  display: flex;
}
.DescriptionContainer {
  /* background-color: maroon; */
  flex: 92;
  text-align: center;
}
.HeaderWhitespace {
  /* background-color: pink; */
  flex: 8;
}

.Content {
  /* background-color: red; */
  /* flex: 90; */
  /* height: 85%; */
  display: flex;
}

.LeftSpace {
  /* background-color: orange; */
  flex: 7;
}
.Projects {
  /* background-color: white; */
  flex: 80;
  /* height: 71vh; */
  height: 82vh;
  /* height: 82%; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;
  align-items: center;
}
.RightSpace {
  /* background-color: green; */
  flex: 13;
}

.readMore {
  font-size: 1.3rem;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 2vh;
}
.readMore:hover {
  cursor: pointer;
}

@media (max-height: 800px) {
  .PortfolioContainer {
    height: 800px;
  }
}

@media (max-width: 800px) {
  .PortfolioContainer {
    height: initial;
  }
  .Header {
    display: initial;
  }
  .Projects {
    flex-wrap: nowrap;
    overflow-y: auto;
    margin-bottom: 2vh;
    height: initial;
  }
}
