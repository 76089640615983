.NavBar {
  position: fixed;
  display: flex;
  /* height: 60px; */
  width: 100vw;
  height: 6vh;
  align-items: center;
  font-size: 15px;
  /* background-color: pink; */
  z-index: 10;
  border-bottom: 1px solid #b3b3b3;
  background-color: white;
}

.LeftSide {
  flex: 1;
  display: flex;
  /* background-color: lightblue; */
  padding-left: 27px;
  height: 100%;
  align-items: center;
}

.LeftSide > div {
  padding: 5px;
}

.LeftSide > img {
  height: 80%;
  padding-right: 2%;
}
.LeftSide > img:hover {
  cursor: pointer;
}

.RightSide {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  /* background-color: red; */
}

.RightSide > a {
  text-decoration: none;
  color: #18a0fb;
}

.a {
  text-decoration: none;
}

.Facebook {
}

.instagram {
}
.linkedin {
}

.resume {
  font-size: 20px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #e5e5e5;
}
.resume:hover {
  cursor: pointer;
}

@media (max-height: 800px) {
  .NavBar {
    height: 48px;
  }
}
