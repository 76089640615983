.FooterContainer {
  background-color: #363636;
  /* height: 684px; */
  /* height: 67vh; */
  display: flex;
  flex-direction: column;
  color: white;
  padding-bottom: 4vh;
  text-align: center;
}

.Header {
  text-align: center;
  flex: 10;
}
.MainContent {
  flex: 90;
}
.IconsSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconsSection div {
  margin: 1%;
}
.IconsSection img {
  max-width: 100%;
  max-height: 100%;
}
.Text {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 4vh;
  margin-bottom: 3vh;
  margin-left: 2vw;
  margin-right: 2vh;
}
.SocialMedia {
  max-width: 60%;
  margin: 0 10px 10px 10px;
  margin: auto;
}
.SocialMedia img {
  padding: 0 2vh;
}
.SocialMedia div:hover {
  cursor: pointer;
}
