:body {
  overflow: hidden;
}
.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 15;
  background-color: white;
  /* opacity: 0.5; */
  text-align: left;
  color: black;
}

.Container {
  max-width: 50vw;
  margin: auto;
  animation: slide-up 0.5s ease;
  padding-bottom: 15vh;
}
/* .Header {
  height: 10vh;
} */

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.CloseButton {
  position: fixed;
  /* top: 10%; */
  top: 80px;
  /* right: 10%; */
  right: 80px;
  /* width: 5vmin; */
}
.CloseButton:hover {
  cursor: pointer;
}
.CloseButton img {
  width: 100%;
}

.Title {
  padding-top: 10vh;
  padding-bottom: 1vh;
  font-size: 2.25rem;
  font-family: "Arsenal", sans-serif;
  font-weight: bold;
}
.Info {
  display: flex;
}
.Stats {
  flex: 75;
  color: #1f1bff;
  font-size: 1.12rem;
}
.Social {
  display: flex;
  flex: 25;
  justify-content: flex-end;
}
.Social > * {
  margin-right: 10px;
  width: 36px;
}
.Social img {
  width: 100%;
}
.Heart {
  text-align: center;
}
.Heart > * {
  margin: 0;
}

.Content img {
  width: 100%;
}
.Content h3,
h2 {
  padding-top: 3vh;
}
.Content p {
  font-size: 18px;
  margin-bottom: 2vh;
}

@media (max-width: 800px) {
  .Title {
    font-size: 1.45rem;
  }
  .Container {
    max-width: 92vw;
  }
  .Header {
    padding-top: 7vh;
  }
  .Stats {
    /* font-size: 0.8rem; */
  }
  .CloseButton {
    /* width: 12vw; */
    width: 48px;
    right: 20px;
    top: 20px;
  }
}
